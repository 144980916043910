<template>
  <div>
    <van-nav-bar
      title="厂家列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div style="margin-bottom: 15px">
      <van-search
        v-model="searchParamValue"
        label="商户名"
        placeholder="请输入商户名关键词"
        @search="onSearch"
        @clear="onSearchClear"
      />
    </div>
    <van-index-bar :index-list="indexList" v-on:select="indexSelect" v-on:change="changeIndex">
      <div v-for="item in dataList" :key="item.index" :name="item.index">
        <van-index-anchor :index="item.index">{{ item.text }}</van-index-anchor>
        <div v-for="subItem in item.subItem" :key="subItem.id" :subItem="item.id">
          <van-contact-card
              type="edit"
              :name="subItem.username"
              :tel="subItem.phone"
              @click="onEdit(subItem.uuid)"
          />
        </div>
      </div>
    </van-index-bar>
  </div>
</template>

<script>
import {ContactCard, IndexAnchor, IndexBar, NavBar, Search} from 'vant';
import * as purchaseService from "@/api/admin/purchase";

export default {
  components: {
    [ContactCard.name]: ContactCard,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [NavBar.name]: NavBar,
    [Search.name]: Search,
  },
  name: "FactoryList",
  data() {
    return {
      searchParam: {},
      searchParamValue: '',
      dataList: [],
      indexList: [],
    };
  },
  created() {
    this.onLoad()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearchClear() {
      this.searchParam.username = null;
      this.onLoad();
    },
    onSearch() {
      this.searchParam.username = this.searchParamValue;
      this.onLoad();
    },
    changeIndex(index) {
      const getAbsentValues = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
          return !arr2.find(obj => {
            return el.index === obj.index;
          });
        });
        return res;
      };

      console.log(index)
      this.searchParam.nameKey = index
      console.log(this.searchParam)
      purchaseService.getAccountUserKeyList(this.searchParam).then(res => {
        console.log("res.data", res.data)
        console.log("getAbsentValues", getAbsentValues(res.data, this.dataList));
        console.log("this.dataList", this.dataList)
        let absentValues = getAbsentValues(res.data, this.dataList)
        if (absentValues.length > 0) {
          absentValues.forEach(i => this.dataList.push(i))
          this.dataList.sort((a, b) => {
            return a.index > b.index ? 1 : -1;
          })
        }
        console.log("this.dataList", this.dataList)
      })
    },
    indexSelect(index) {
    },
    onLoad() {
      purchaseService.getAccountUserKeyList(this.searchParam).then(res => {
        this.indexList = res.pinyinKeys
        this.dataList = res.accountUserIndexVos
      })
    },
    onEdit(uuid) {
      this.$router.push({path: '/factory/particulars/' + uuid})
    },
  },
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
